<template>
  <div class="trick-box">
    <div class="trick-header">
      <div class="header-content">
        <slot name="title"><span>{{item.craftTitle}}</span></slot>
        <span class="send-btn" @click="sendBtn">发送</span>
      </div>
    </div>
    <div class="trick-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
  import { Toast } from 'vant';
  import sidebar from '@/api/sidebar';
  export default {
    props: {
      item: {
        type: Object,
        default: ()=> null
      }
    },
    name: 'trickHeader',
    methods: {
      async sendBtn() {
        let { code, data } = await sidebar.mediaIdVali({opId: localStorage.getItem('platform_opId'), craftId: this.item.id});
        if (code !== '0') { return ; }
        //0文字1图片2视频3网页(cme内容活动)4小程序5文档6自定义网页
        let map = {
          0: {
            msgtype: 'text',
            enterChat: false,
            text: {
              content: data.craftContent
            }
          },
          1: {
            msgtype: 'image',
            enterChat: false,
            image:
              {
                mediaid: data.attachType === 1 && data.mediaId, //图片的素材id
              },
          },
          2: {
            msgtype: 'video',
            enterChat: false,
            video:
              {
                mediaid: data.attachType ===2 && data.mediaId, //视频的素材id
              },
          },
          4: {
            msgtype: 'miniprogram',
            enterChat: false,
            miniprogram: data.attachType ===4 && {
              appid: data.appId, //小程序的appid，企业已关联的任一个小程序
              title: data.shareTitle, //小程序消息的title
              imgUrl: data.frontPic, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
              page: data.appPage, //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
            },
          },
          5: {
            msgtype: 'file',
            enterChat: false,
            file:
              {
                mediaid: data.attachType === 5 && data.mediaId, //视频的素材id
              },
          },
          6: {
            msgtype: 'news',
            enterChat: false,
            news: data.attachType === 6 && {
              link: data.attachPath, //H5消息页面url 必填
              title: data.shareTitle, //H5消息标题
              desc: data.attachAbstract, //H5消息摘要
              imgUrl: data.frontPic, //H5消息封面图片URL
            },
          },
        };
        let req = data.attachType ? map[data.attachType]:map[0];
        this.$wx.invoke('sendChatMessage', req, function(res) {
          if (res.err_msg == 'sendChatMessage:ok') {
            //发送成功
            Toast('发送成功');
          }
        });
      },
    }
  };
</script>

<style lang="less" scoped>
  .trick-box{
    background: #fff;
    .trick-header{
      margin: 0 12px;
      border-bottom: 1px solid #F6F6F6;
      .header-content{
        height: 44px;
        font-size: 14px;
        font-weight: 500;
        color: #303133;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .send-btn{
          width: 64px;
          height: 32px;
          line-height: 32px;
          text-align:center;
          background: #F0F4FE;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 400;
          color: #3A74F2;
        }
      }
    }
    .trick-content{
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 12px 16px 12px;
      .img-content{
        width: 100%;
        height: 226px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>
