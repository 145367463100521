<template>
  <div class="trick-text">
    <trickHeader :item="item">
      <div class="text-content" slot="content">
        {{item.craftContent}}
      </div>
    </trickHeader>
  </div>
</template>

<script>
import trickHeader from './trickHeader';

export default {
  name: 'trickText',
  components: {trickHeader},
  props: {
    item: {
      type: Object,
      default: ()=> null
    }
  },
};
</script>

<style lang="less" scoped>
  .text-content{
    text-align: left;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #606266;
  }
</style>
