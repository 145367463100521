<template>
  <div class="trick-chest">
    <div class="myclient-search">
      <div class="project" v-if="!onePro" @click="show = true">
        <span>{{ projectName }}</span
        ><img class="allow-down-icon" src="../../../assets/images/allow-down-icon.png" alt="" />
      </div>
      <form class="search" action="/">
        <van-search v-model="keyword" placeholder="输入内容标题" @clear="search" @search="search" />
      </form>
    </div>

    <van-tabs v-if="showTabList" ref="wxbTabs" v-model="active" sticky swipeable @change="change">
      <van-tab :title="item.categoryName" v-for="(item, index) of tabList" :key="index" :title-class="orderIndex === index ? ['area-tab'] : ''">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list class="content" v-if="trickList.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="100">
            <component style="margin-bottom:12px" v-for="(item, index) of trickList" :key="index" :item="item" :is="componentsName[item.craftType]"></component>
          </van-list>
          <wxb-no-data v-else />
        </van-pull-refresh>
      </van-tab>
    </van-tabs>

    <div class="search-content" v-if="!showTabList">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list class="content" v-if="trickList.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="100">
          <component style="margin-bottom:12px" v-for="(item, index) of trickList" :key="index" :item="item" :is="componentsName[item.craftType]"></component>
        </van-list>
        <wxb-no-data v-else />
      </van-pull-refresh>
    </div>

    <!-- 项目弹窗S-->
    <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" close-on-click-action get-container="#app" @select="selectProj" />
    <!-- 项目弹窗E -->
  </div>
</template>

<script>
import trickFile from './components/trickFile.vue';
import trickImage from './components/trickImage.vue';
import trickMini from './components/trickMini.vue';
import trickText from './components/trickText.vue';
import trickUrl from './components/trickUrl.vue';
import trickVideo from './components/trickVedio.vue';
import sidebar from '@/api/sidebar';
import { wxAgentConfig } from '../../../util/util';
export default {
  components: { trickVideo, trickFile, trickImage, trickMini, trickText, trickUrl },
  data() {
    return {
      showTabList: true,
      // 0文字1图片2视频3网页(cme内容活动)4小程序5文档6自定义网页
      componentsName: {
        0: 'trickText',
        1: 'trickImage',
        2: 'trickVideo',
        6: 'trickUrl',
        4: 'trickMini',
        5: 'trickFile',
      },
      onePro: true,
      show: false,
      loading: false,
      finished: false,
      refreshing: false,
      actions: [],
      tabList: [],
      trickList: [],
      projectName: '',
      keyword: '',
      opId: '',
      estateId: '',
      active: 0,
      pageNation: {
        pageNo: 0,
        pageSize: 20,
        totalRecords: 0,
      },
    };
  },
  computed: {
    orderIndex() {
      return this.tabList.findIndex((item) => item.type === 1);
    },
  },
  methods: {
    //获取项目
    async getSidebarEstates() {
      let { data } = await sidebar.getSidebarEstates({ opId: this.opId });
      if (!data) return;
      if (data.length >= 2) {
        this.onePro = false;
      }
      if (!localStorage.getItem('platform_sidebarEstateId')) {
        localStorage.setItem('platform_sidebarEstateId', data[0].estateId);
      }
      this.actions = data.map((item) => {
        item.name = item.estateShowName;
        if (item.estateId === localStorage.getItem('platform_sidebarEstateId')) {
          item.color = '#3A74F2';
          this.projectName = item.estateShowName;
          this.estateId = item.estateId;
        } else {
          item.color = '';
        }
        return item;
      });
    },
    async selectProj(val) {
      localStorage.setItem('platform_sidebarEstateId', val.estateId);
      this.projectName = val.estateShowName;
      this.estateId = val.estateId;
      this.actions = this.actions.map((item) => {
        if (item.estateId === val.estateId) {
          item.color = '#3A74F2';
        } else {
          item.color = '';
        }
        return item;
      });
      this.active = 0;
      this.keyword = '';
      this.trickList = [];
      this.pageNation.pageNo = 0;
      await this.getCategoryList();
      await this.getCraftList();
    },
    //获取话术类别
    async getCategoryList() {
      let params = { opId: this.opId, estateId: this.estateId };
      let { code, data } = await sidebar.getCategoryList(params);
      if (code === '0') {
        this.tabList = data;
      }
    },
    search() {
      if (this.keyword) {
        this.showTabList = false;
      } else {
        this.showTabList = true;
      }
      this.trickList = [];
      this.pageNation.pageNo = 0;
      this.getCraftList();
    },
    //获取话术
    async getCraftList() {
      let params = { opId: this.opId, estateId: this.estateId, categoryId: this.keyword ? null : this.tabList[this.active].categoryId, craftTitle: this.keyword, pageNo: this.pageNation.pageNo, pageSize: this.pageNation.pageSize };
      let { code, data } = await sidebar.getCraftList(params);
      this.loading = false;
      if (code === '0') {
        this.trickList = this.trickList.concat(data.data);
        this.pageNation.totalRecords = data.totalRecords;
        if (this.trickList.length >= this.pageNation.totalRecords) {
          this.finished = true;
        }
      } else {
        this.finished = true;
      }
    },
    //下拉刷新
    onRefresh() {
      this.pageNation.pageNo = 0;
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.trickList = [];
        this.refreshing = false;
      }
      this.pageNation.pageNo += 1;
      this.getCraftList();
    },
    //标签选择
    change() {
      this.trickList = [];
      this.pageNation = {
        pageNo: 0,
        pageSize: 20,
        totalRecords: 0,
      };
      this.onLoad();
    },
    cb() {
      console.log('cb');
    },
  },
  async mounted() {
    wxAgentConfig(this.cb, this, ['sendChatMessage']);
    this.opId = localStorage.getItem('platform_opId');
    await this.getSidebarEstates();
    await this.getCategoryList();
    await this.getCraftList();
  },
};
</script>

<style lang="less" scoped>
.trick-chest {
  .content {
    padding: 16px;
  }
  .myclient-search {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f5f6f7;
    .project {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-right: 16px;
      span {
        max-width: calc(100% - 16px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #303133;
      }
      .allow-down-icon {
        width: 6px;
        height: 5px;
        margin-left: 4px;
        vertical-align: 2px;
      }
    }
    .search {
      flex: 3;
    }
  }
}
</style>
<style lang="less">
.trick-chest {
  .area-tab {
    position: relative;
    &::before {
      position: absolute;
      left: 0px;
      top: 20%;
      content: "";
      width: 1px;
      height: 60%;
      background-color: #f6f6f6;
    }
  }
}
</style>
