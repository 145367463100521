<template>
  <trickHeader :item="item">
    <div class="video-content" slot="content">
      <div :class="item.type === 'customer' ? ['chat-video', 'customer-boder'] : ['chat-video', 'sales-boder']">
        <video-player  class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="false" :options="playerOptions"></video-player>
      </div>
    </div>
  </trickHeader>

</template>
<script>
import 'video.js/dist/video-js.css';

import { videoPlayer } from 'vue-video-player';
import trickHeader from './trickHeader';
export default {
  name: 'trickVideo',
  components: { videoPlayer, trickHeader },
  props: {
    item: {
      default: () => null,
    },
  },
  data() {
    return {
      playerOptions: {
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            // mp4
            src: '',
          },
        ],
        poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-1.jpg', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          volumePanel: {
            inline: false,
          },
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },
  mounted() {
    this.playerOptions.sources[0].src=this.item.craftAttach.attachPath;
  }
};
</script>
<style lang="less">
.video-content {
  width: 100%;
  .video-js {
    .vjs-icon-placeholder {
      width: 100%;
      height: 100%;
      display: block;
    }
    .vjs-big-play-button{
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

}
</style>
