<template>
  <trickHeader :item="item">
    <div class="file-content" slot="content">
      <svg
        class="icon-arrow icon-file"
        aria-hidden="true"
      >
        <use xlink:href="#iconwenjianxiazai"></use>
      </svg>
      <span>{{item.craftAttach.attachName}}</span>
    </div>
  </trickHeader>
</template>

<script>
import trickHeader from './trickHeader';

export default {
  name: 'trickFile',
  components: { trickHeader },
  props: {
    item: {
      default: () => null,
    },
  },
};
</script>

<style lang="less" scoped>
  .file-content{
    background: rgba(48, 49, 51, 0.04);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    .icon-file{
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
  }

</style>
