<template>
  <trickHeader :item="item">
    <div class="url-content" slot="content">
      <img :src="item.craftAttach.frontPic" alt="">
      <div class="content-right">
        <h6>{{item.craftAttach.shareTitle}}</h6>
        <span>{{item.craftAttach.attachAbstract}}</span>
      </div>
    </div>
  </trickHeader>
</template>

<script>
  import trickHeader from './trickHeader';

  export default {
    name: 'trickUrl',
    components: { trickHeader },
    props: {
      item: {
        default: () => null,
      },
    },
  };
</script>

<style lang="less" scoped>
  .url-content{
    width: 100%;
    background: rgba(48, 49, 51, 0.04);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 14px 12px;
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    img{
      width: 54px;
      height: 54px;
      margin-right: 8px;
    }
    .content-right{
      h6{
        font-size: 14px;
        font-weight: 400;
        color: #303133;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
      }
      span{
        font-size: 12px;
        font-weight: 400;
        color: #909399;
        line-height: 17px;
      }
    }
  }

</style>
