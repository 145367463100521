<template>
  <trickHeader :item="item">
    <div class="img-content" slot="content">
      <img :src="item.craftAttach.attachPath" alt="">
    </div>
  </trickHeader>
</template>

<script>
import trickHeader from './trickHeader';

export default {
  name: 'trickImage',
  components: {trickHeader},
  props: {
    item: {
      type: Object,
      default: ()=> null
    }
  },
  methods: {

  }
};
</script>

<style lang="less" scoped></style>
