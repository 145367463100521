<template>
  <trickHeader :item="item">
    <div class="mini-content" slot="content">
      <div class="mini-header">
        <img src="../../../../assets/images/default_head_icon.png" alt="">
        <span>小程序</span>
      </div>
      <div class="mini-title">{{item.craftAttach.attachName}}</div>
      <div class="mini-img">
        <img :src="item.craftAttach.attachPath" alt="">
      </div>
      <div class="mini-footer">
        <svg
          class="icon-arrow icon-mini"
          aria-hidden="true"
        >
          <use xlink:href="#iconxiaochengxu"></use>
        </svg>
        <span>小程序</span>
      </div>
    </div>
  </trickHeader>
</template>

<script>
import trickHeader from './trickHeader';

export default {
  name: 'trickMini',
  components: { trickHeader },
  props: {
    item: {
      default: () => null,
    },
  },
};
</script>

<style lang="less" scoped>
  .mini-content{
    width: 100%;
    box-sizing: border-box;
    padding: 12px 12px 8px 12px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 4px;
    border: 1px solid #F6F6F6;
    .mini-header{
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #909399;
      line-height: 17px;
      img{
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
    .mini-title{
      font-size: 14px;
      font-weight: 400;
      color: #303133;
      margin-top: 10px;
    }
    .mini-img{
      padding: 8px 0;
      border-bottom: 1px solid #F6F6F6;
      height: 221px;
      overflow: hidden;
      img{
        height: 221px;
        width: 100%;
        object-fit: cover;
      }
    }
    .mini-footer{
      padding-top: 8px;
      .icon-mini{
        width: 16px;
        height: 16px;
      }
      font-size: 12px;
      font-weight: 400;
      color: #606266;
      display: flex;
      align-items: center;
    }
  }
</style>
